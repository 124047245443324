import React from 'react';

const PanelWrapper = ( {title, children} ) => (
  <div className="well">
    <h3> {title} </h3>
    <div>
      {children}
    </div>
  </div>
);

export default PanelWrapper;