import React from 'react';
import classNames from 'classnames';
import { formatCurrency } from 'app/utils';

const LimitRow = ({ title, limit, isInitialState, className }) => (
  <div className={classNames('limit-row', className)}>
    <h4 className="limit-row-title">{title}</h4>
    <div className="limit-row-value">
      {isInitialState && <i className="fa fa-spinner fa-spin" />|| formatCurrency(limit)}
    </div>
  </div>
);

function renderLimitRow(title, limit, isInitialState, className) {
  if (limit) {
    return (
      <LimitRow
        {...{
          limit, title, isInitialState, className,
        }}
      />
    );
  }
}

const CustomerCreditTable = ({ creditLimit, securityDeposit, isInitialState }) => (
  <div className="customer-credit">
    {renderLimitRow('Credit Limit', creditLimit, isInitialState, 'text-center alert-balance')}
    {renderLimitRow('Security Deposit', securityDeposit, isInitialState, 'text-center lead alert alert-balance')}
  </div>
);

export default CustomerCreditTable;
