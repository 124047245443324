import React from "react";
import AccountBalance from "components/account-balance";
import AddCredits from "./add-credits";
import classNames from "classnames";
import {formatCurrency, getBalanceStatusClasses} from "app/utils";

const classes = classNames("panel-balance-header");

class AccountBalanceSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
    };
  }

  toggleState = () => {
    this.setState((prevState) => ({
      hidden: !prevState.hidden,
    }));
  };

  handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
      this.toggleState();
    }
  };

  render() {
    const { showForm, customer } = this.props;
    const isLoading = this.props.isInitialState || this.props.isLoading;
    const containerClasses = classNames("panel-balance-container", {
      "panel-balance-container-hidden": this.state.hidden,
    });
    const balanceStatus = getBalanceStatusClasses(
      this.props.balance,
      this.props.creditLimit,
      this.props.balance <= this.props.warningThreshold,
      this.props.isPostpay
    );

    let balanceClasses = classNames("panel-balance-header", {
      "panel-warning": balanceStatus["alert-warning"],
      "panel-negative": balanceStatus["alert-danger"],
    });
    if (isLoading) {
      balanceClasses = classNames("panel-balance-header");
    }

    const shouldDisplayForm =
      showForm &&
      customer.status === "A" &&
      (!customer.reseller || customer.reseller.is_rebranded != true);

    if (shouldDisplayForm) {
      return (
        <div className={containerClasses}>
          <div
            className={balanceClasses}
            onClick={this.toggleState}
            onKeyDown={this.handleKeyDown}
            role="button"
            aria-label={`${formatCurrency(this.props.availableFunds)} Account balance`}
            aria-expanded={!this.state.hidden}
            tabIndex="0"
          >
            <AccountBalance
              {...this.props}
              classNames="account-balance-indicator"
            />
            <span className="panel-balance-arrow">
              <i className="fa fa-angle-up"></i>
            </span>
          </div>
          <div className="panel-balance-body" aria-hidden={this.state.hidden}>
            <AddCredits
              hideFocus={this.state.hidden}
              defaultPaymentSource={this.props.defaultPaymentSource}
              submitClassName="submit-button sidebar btn btn-medium btn-success"
              className="sidebar m3-form"
              text="Add"
            >
              <a
                tabIndex={this.state.hidden ? "-1" : "0"}
                title="Configure Balance Settings"
                href="/accounts/preferences/payments/"
              >
                <i className="fa fa-cog"></i> Balance Settings
              </a>
            </AddCredits>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default AccountBalanceSidebar;
