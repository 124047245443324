import React from 'react';
import SubmitButton, {formStates} from 'components/submit-button';
import CSRFForm from 'components/csrf-form';
import 'styles/_add-credits.scss';

class AddCredits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: formStates.READY,
    };
  }

  render() {
    const tabindex = this.props.hideFocus ? '-1' : '0';

    return (
      <CSRFForm
        className={`add-credits ${this.props.className || ''}`}
        action="/accounts/addcredit/"
        method="post"
        onSubmit={() => this.setState({ formState: formStates.NOT_READY })}
      >
        <input type="hidden" name="credit_cards" value={this.props.defaultPaymentSource || ''} />
        <input
          tabIndex={tabindex}
          className="add-credits-input"
          placeholder="Amount"
          aria-label="Amount"
          ref={input => this.input = input}
          id="id_amount"
          type="text"
          name="amount"
          size="8"
        />
        <SubmitButton
          tabIndex={tabindex}
          text={this.props.text || 'Add Funds'}
          className={this.props.submitClassName}
          formState={this.state.formState}
        />
        <div className="extra-fields">
          {this.props.children}
        </div>
      </CSRFForm>
    );
  }
}

export default AddCredits;
