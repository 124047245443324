import React from 'react';

const AnchorButton = ( {href="#", className='btn-info', ...props} ) => (
  <a
    onClick={props.onClick}
    title={(props.text || props.children)}
    href={href}
    className={`btn btn-block ${className}`}
  >
    {props.icon} {(props.text || props.children)}
  </a>
);

export default AnchorButton;
