import React from 'react';
import AccountBalance from 'components/account-balance';
import PanelWrapper from 'components/panel-wrapper';
import AnchorButton from 'components/anchor-button';
import AddCredits from './add-credits';
import CustomerCreditTable from './customer-credit-table';

function renderCustomerCreditTable(props) {
  if (props.creditLimit) {
    return (
      <CustomerCreditTable
        securityDeposit={props.customer.securityDeposit}
        { ...props }
      />
    );
  }
}

function renderAddCreditsForm(showForm, customer) {
  const shouldDisplayForm = (
    showForm &&
    customer.status === 'A' &&
    (!customer.reseller || customer.reseller.is_rebranded != true)
  );

  if (shouldDisplayForm) {
    return (
      <div className="add-credits-form">
        <AddCredits
          defaultPaymentSource={customer.defaultPaymentSource}
        />
        <AnchorButton
          href="/accounts/preferences/payments/#low_balance"
          icon={<i className="icon-envelope"></i>}
          className='btn-warning'
          text="Setup Low Balance Notification"
        />
      </div>
    );
  }
}

const AccountBalancePanel = (props) => {
  var title = props.isSummaryView ? "Balance Across All Accounts" : "Balance";
  return (
      <PanelWrapper title={title}>
        <AccountBalance title {...props} />
        {renderCustomerCreditTable({ ...props})}
        {renderAddCreditsForm(props.showForm, props.customer)}
      </PanelWrapper>
  );
};

export default AccountBalancePanel;
