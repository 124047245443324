import React from 'react';
import classNames from 'classnames';
import { formatCurrency, getBalanceStatusClasses } from '../utils';

function AccountBalance( {title, availableFunds = 0, balance = 0, warningThreshold = 0, isPostpay, creditLimit, ...props} ) {
  const isBalanceLow = balance <= warningThreshold;
  const isLoading = props.isInitialState || props.isLoading;
  let classes = props.classNames || classNames(
    'text-center',
    'lead',
    'alert',
    'alert-balance',
    getBalanceStatusClasses(balance, creditLimit, isBalanceLow, isPostpay),
  );
  if (isLoading && !props.classNames) {
    classes = classNames(
      'text-center',
      'lead',
      'alert',
      'alert-balance',
      'alert-success',
    );
  }
  return (
    <div className={classes}>
      {title && <h4>Available Funds</h4>}
      <a
        className="panel-balance-amount"
        data-toggle="tooltip"
        title=""
        data-original-title={`$${availableFunds}`}
      >
        {isLoading && <i className="fa fa-spinner fa-spin"></i> || formatCurrency(availableFunds)}
      </a>
    </div>
  );
};

export default AccountBalance;
