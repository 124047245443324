import 'react-hot-loader/patch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { AppContainer } from 'react-hot-loader';

import { store } from 'app/redux/store';
import { fetchBalance } from 'actions/balance';
import { fetchCustomer } from 'actions/customer';
import * as Task from 'app/task';

import { exchange_credentials } from 'app/django-adapter';

import AccountBalancePanel from 'app/apps/account-balance-panel/components/panel';
import AccountBalanceSidebar from 'app/apps/account-balance-panel/components/sidebar';
import ErrorBoundary from 'components/error/error-boundary';
import './style.scss';

let refreshIntervalId = null;

window.updateBalance = (errorCallback, ...args) => {
  window.clearInterval(refreshIntervalId);
  return Task.fetchBalanceData(...args)(store.dispatch)
    .then(() => {
      refreshIntervalId = window.setInterval(() => {
        Task.fetchBalanceData(null, false)(store.dispatch);
      }, 60000);
    })
    .catch((e) => {
      if (errorCallback) {
        errorCallback(e);
      }
    });
};

export class App extends React.Component {
  failedTries = 0;

  UNSAFE_componentWillMount() {
    const { fetchCustomer, isInitialState } = this.props;

    if (!isInitialState) return;

    fetchCustomer().then(this.fetchBalanceAndHandleError);
  }

  refreshIntervalId = () => {
    refreshIntervalId = window.setInterval(this.props.fetchBalance, 60000);
  }

  fetchBalanceAndHandleError = () => {
    const { fetchBalance } = this.props;
    fetchBalance()
      .then(this.refreshIntervalId)
      .catch((e) => {
        window.setTimeout(() => {
          this.fetchBalanceAndHandleError.call(this);
        }, 5000 * (++this.failedTries));
      });
  }

  render() {
    const { showForm, Component } = this.props;

    return (
      <Component showForm={showForm} {...this.props} />
    );
  }
}

const ConnectedApp = connect(
  ({ balance, customer }) => ({ ...balance, customer }),
  { fetchBalance, fetchCustomer },
)(App);

export const Root = props => (
  <Provider store={store}>
    <AppContainer>
      <ErrorBoundary>
        <ConnectedApp {...props} />
      </ErrorBoundary>
    </AppContainer>
  </Provider>
);

/* istanbul ignore next */
const render = (el, props) => () => {
  /* istanbul ignore next */
  ReactDOM.render(
    <Root {...props} />,
    el,
  );
};

window.renderBalanceComponent = (el, { sidebar, showForm = true, isSummaryView = false }) => {
  /* istanbul ignore next */
  const Component = sidebar && AccountBalanceSidebar || AccountBalancePanel;

  /* istanbul ignore next */
  exchange_credentials(store)
    .then(render(el, { showForm, isSummaryView, Component }))
    .catch(console.warn.bind(console));

  /* istanbul ignore next */
  if (module.hot) {
    /* istanbul ignore next */
    module.hot.accept('./app.js', render);
  }
};
